.job-seeking {
  background-color: #ffca28;
}

.not-job-seeking {
  background-color: #eaeaec;
}

.profile {
  margin-left: 5rem;
  flex: 1;
}

.profile-text-container {
  margin: 2rem 0;
}

.profile-text {
  font-size: 1.25rem;
  font-weight: 600;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}