.featured-project-card {
  max-width: 100%;
  margin: 0 5rem;
}

.featured-project-image {
  max-width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.featured-project-image:hover {
  opacity: 0.8;
}

.featured-project-title {
  font-size: 1.25rem;
  margin: 0.5rem 0 0 0;
}

.featured-project-stack {
  width: 100%;
  display: inline-block;
  text-align: left;
  margin-top: 0.5rem;
}