.avatar {
    width: 6rem;
    height: 6rem;
    max-width: 6rem;
    max-height: 6rem;
    border-radius: 50rem;
}

.image-link:hover {
  opacity: 0.8;
}

.name {
    font-size: 2rem;
    margin: 0;
}

.title {
    display: inline-block;
    font-size: 1rem;
    margin: 0;
}

.name-title {
    display: inline-block;
    margin-left: 1rem;
}

.header-item {
    display: inline-block;
    margin: 1rem 1rem;
    font-size: 1.25rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.header {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    position: sticky;
    top: 0;
    width: 100%;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

@media (min-width: 700px) {
    .name {
        font-size: 3rem;
        margin: 0;
    }
    
    .title {
        font-size: 1.5rem;
    }
}

@media (min-width: 1000px) {
    .header {
        flex-direction: row;
    }

    .header-item {
        margin: 0 1rem;
        padding-top: 0;
        padding-bottom: 0;
    }
}