.footer-nav {
    margin-top: 1rem;
    margin-bottom: 5rem;
}

.footer-nav-item {
    display: inline;
    margin: 0 0.5rem;
}

.footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}