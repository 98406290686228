.project-card {
  width: 80%;
  max-width: 80%;
  margin-bottom: 2rem;
}

.project-image {
  max-width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.project-image:hover {
  opacity: 0.8;
}

.project-title {
  font-size: 1.25rem;
  margin: 0.5rem 0 0 0;
}

.project-stack {
  width: 100%;
  display: inline-block;
  text-align: left;
  margin-top: 0.5rem;
}

.hidden {
  visibility: hidden;
}

@media (min-width: 1000px) {
  .project-card {
    width: 30%;
    max-width: 30%;
    margin-bottom: 5rem;
  }
}