.hero {
  width: 100%;
  display: flex;
  align-items: center;
}

.featured-project {
  display: none;
}

.featured-project-heading {
  margin-left: 5rem;
  font-size: 1.5rem;
}

@media (min-width: 1400px) {
  .featured-project {
    flex: 1;
    display: block;
  }
}