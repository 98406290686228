body {
  margin: 0;
  padding: 1rem 2rem 0 1rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a, a:focus, a:active {
  text-decoration: none;
  color: inherit;
}

a:hover {
  color: rgba(0, 0, 0, 0.7);
}

ul, li {
  list-style-type: none;
  list-style-position: inside;
  margin: 0;
  padding: 0; 
}

.card {
  padding: 1rem 1rem;
  border: 1px solid #757577;
  border-radius: 0.5rem;
}

.tag { 
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  display: inline-block;
  white-space: nowrap;
}

@media (min-width: 700px) {
  body {
    padding: 5rem 10rem 0 10rem;
  }
}