.project-heading {
  font-size: 1.5rem;
}

.project-grid {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 2rem;
}