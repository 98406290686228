.contact-icon {
    margin: 0.5rem 0.5rem 0.5rem 0;
}

.contact-nav {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

@media (min-width: 700px) {
    .contact-nav {
        width: 50%;
    }
}